import { Link } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import FastAppsLogoImage from "../../../src/content/img/fastapps-logo.svg";
import FastAppsLogoWhiteImage from "../../../src/content/img/fastapps-logo-white.svg";

import config from '../../website-config';

export const FastAppsLogo = () => (
  <img src={FastAppsLogoImage} alt={config.title} />
);

export const FastAppsLogoWhite = () => (
  <img src={FastAppsLogoWhiteImage} alt={config.title} />
);

export const SiteNavLogo = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
    <FastAppsLogo />
  </Link>
);

export const SiteNavLogoWhite = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
    <FastAppsLogoWhite />
  </Link>
);

const SiteNavLogoStyles = css`
  position: relative;
  z-index: 100;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 32px;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-transform: none;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 50px;
  }
`;

